import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Hero from "components/hero/Hero";
import About from "components/features/AboutMe"
import Services from "components/features/Services";
import Projects from "components/projects/projects";
import Footer from "components/footers/Footer";
import CTA from "components/cta/cta1";
import Customers from "components/cards/Customers";

const IndexPage = () => {
  return (
    <div>
      <AnimationRevealPage disabled>
        <Hero />
        <Services />
        <About />
        <CTA />
        <Customers />
        <Projects />
        <Footer />
      </AnimationRevealPage>
    </div>
  );
}

export default IndexPage;
