import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import MonitorIconImage from "../../images/monitor.svg";
import PresentationIconImage from "../../images/presentation.svg";
import HarddriveIconImage from "../../images/hard-drive.svg";
import GlobeIconImage from "../../images/globe.svg";
import CodeIconImage from "../../images/code.svg";
import LayoutIconImage from "../../images/layout.svg";
import ThumbsUpIconImage from "../../images/thumbs-up.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-primary-500 rounded-2xl mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
  .type {
    ${tw`mt-3 font-semibold text-white text-sm bg-primary-500 px-2 py-1 rounded-lg leading-loose`}
  }
`;

export default () => {
  const cards = [
    {
      imageSrc: MonitorIconImage,
      title: "IT-Support",
      description: "Probleme mit Computer, Handy, etc.? Keine Sorge! Wir kümmern uns darum, dass ihre Geräte wieder so laufen wie gewohnt.",
      type: "Für Privatpersonen"
    },
    {
      imageSrc: PresentationIconImage,
      title: "IT-Beratung",
      description: "Digitalisierung spielt eine wichtige Rolle für Unternehmen. Wir beraten Sie, damit Sie Ihr Vorhaben umsetzen können.",
      type: "Für Unternehmen"
    },
    {
      imageSrc: HarddriveIconImage,
      title: "IT-Verwaltung",
      description: "Wir kümmern uns um sämtliche IT-Systeme in Ihrem Unternehmen, sodass Sie sich auf die wichtigen Dinge konzentrieren können.",
      type: "Für Unternehmen"
    },
    {
      imageSrc: CodeIconImage,
      title: "Softwareentwicklung",
      description: "Wir entwickeln Software, die exakt auf Ihre Bedürfnisse zugeschnitten sowie einfach zu bedienen ist und genau das kann, was Sie brauchen.",
      type: "Für Unternehmen & Privatpersonen"
    },
    {
      imageSrc: LayoutIconImage,
      title: "Web Entwicklung",
      description: "Benötigen Sie eine Website oder eine Webanwendung? Wir entwickeln für Sie eine moderne Webseite nach Ihren Bedürfnissen.",
      type: "Für Unternehmen & Privatpersonen"
    },
    {
      imageSrc: ThumbsUpIconImage,
      title: "Nachhaltigkeit",
      description: "Unser Unternehmen setzt auf grüne Technologie. Egal ob bei Geräten oder dem Weg zum Kunden. Wir arbeiten nach bestem Wissen und Gewissen.",
      type: "Für Unternehmen & Privatpersonen"
    }
  ];

  return (
    <Container id="services">
      <ThreeColumnContainer>
        <Heading>Unsere <span tw="text-primary-500">Leistungen</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
                <p className="type">{card.type}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
