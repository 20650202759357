import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";
import PundPLogo from '../../images/pundp.png';
import VispecsLogo from '../../images/vispecs.png';
import KunzLogo from '../../images/psychotherapie-kunz.jpg';
import S3Logo from '../../images/s3.jpg';

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center transition duration-200 ease-in-out hover:opacity-75`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-cover bg-center rounded-xl cursor-pointer`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

export default ({
  cards = [
    {
      imageSrc: PundPLogo,
      position: "Sozialforschung",
      name: "P und P Sozialforschung",
      link: "http://pundpsozialforschung.at",
    },
    {
      imageSrc: VispecsLogo,
      position: "Digitaler Optiker",
      name: "ViSpecs GmbH",
      link: "http://vispecs.com",
    },
    {
      imageSrc: S3Logo,
      position: "Service, Schwimmbad, Sauna",
      name: "S3 - Wolfgang Birklbauer",
      link: "http://sdrei.at",
    },
    {
      imageSrc: KunzLogo,
      position: "Psychotherapeutin",
      name: "Psychotherapie Marianne Kunz",
      link: "http://psychotherapie-kunz.at"
    }
  ]
}) => {
  return (
    <Container id="customers">
      <ContentWithPaddingXl>
        <HeadingContainer>
          {'Unsere Kunden' && <Heading>Unsere <span tw="text-primary-500">Kund*innen</span></Heading> }
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <a tw="flex flex-col items-center" target="_blank" href={card.link} rel="nofollow">
                <CardImage imageSrc={card.imageSrc} />
                <CardContent>
                  <span className="position">{card.position}</span>
                  <span className="name">{card.name}</span>
                </CardContent>
              </a>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
