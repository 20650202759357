import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Link, Redirect, Switch} from "react-router-dom";
//import "style.css";
import "tailwindcss/dist/base.css";
import IndexPage from "pages/index";
import ImprintPage from "pages/imprint";
import CookieModal from "components/legal/cookieModal";
import CookieConsent from "react-cookie-consent";

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <IndexPage />
          </Route>
          <Route path="/imprint">
            <ImprintPage />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
      <CookieConsent
        buttonText="Ich verstehe"
        style={{ background: "#4299e1" }}
  buttonStyle={{ borderRadius: 5, background: "#ffffff", color: "#000000", fontSize: "13px" }}
      >
        Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern. Durch die Nutzung unserer Website akzeptieren Sie die gewählten Cookies gemäß unserer Cookie-Richtlinie oder Sie verlassen diese. Weitere Informationen hierzu finden Sie in unserer <a style={{textDecoration: 'underline'}} href="http://poscher.me/imprint">Datenschutzerklärung.</a>
      </CookieConsent>
    </>
  );
}

export default App;
