import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import profileImage from "../../images/pb.jpg";

const Container = tw.div`relative mt-4`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto p-10`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded-3xl bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`w-full`;
const Heading2 = tw(SectionHeading)`text-left text-2xl sm:text-3xl lg:text-4xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`

export default ({textOnLeft = false}) => {
  return (
    <Container id="about">
      <Heading>Wer <span tw="text-primary-500">wir</span> sind</Heading>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={profileImage} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading2>Alexander Poscher</Heading2>
            <Description>
              <span tw="text-secondary-400 font-bold">Softwareentwickler, Web Designer, Computer-Spezialist</span><br/><br/>
              Nach meinem Abschluss am <a tw="text-primary-500 font-bold underline" href="http://www.litec.ac.at" target="_blank">Linzer Technikum</a> habe ich bei einer Startup-Idee mitgearbeit und ein soziales <a tw="text-primary-500 font-bold underline" href="http://eventguide.poscher.me" target="_blank">Projekt für Unternehmen</a>, die durch Corona in Schwierigkeiten gekommen sind, gestartet.
              Seit Oktober dieses Jahres arbeite ich als Selbstständiger in der IT-Branche und studiere an der <a tw="text-primary-500 font-bold underline" href="http://www.jku.at" target="_blank">Johannes Kepler Universität Linz</a>. <br/><br/>
              Durch meine zahlreichen Projekte und Erfahrungen bin ich ständig mit den neuesten Technologien konfrontiert. Dadurch werden meine Fähigkeiten stets erweitert.<br/><br/>
              Einige von mir verwendete Technologien:<br/> <span tw="text-black">JavaScript, Node.js, React, Vue, HTML, CSS, C#, Java</span>
            </Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
