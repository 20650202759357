import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/Footer";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Impressum" }) => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p><b>Informationspflicht lt. §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch bzw. §63 Gewerbeordnung und Offenlegungspflicht lt. §25 Mediengesetz</b></p>
            <p>Alexander Poscher<br/>Oberbaumgarten 50<br/>4204 Haibach im Mühlkreis<br/>Österreich</p>
            <p>Tel: +43 677 64 16 16 44</p>
            <p>E-Mail: office@poscher.me</p>
            <p>Rechtsform: <b>Einzelunternehmen</b></p>
            <p>Anwendbare Rechtsvorschriften: <b>Berufsrecht</b>, Gewerbeordnung: <a tw="text-primary-500" href="http://www.ris.bka.gv.at">www.ris.bka.gv.at</a><br/>
              Aufsichtsbehörde/Gewerbebehörde: <b>Bezirkshauptmannschaft Urfahr-Umgebung</b><br/>
              Unternehmensgegenstand: <b>Softwareentwicklung, IT-Dienstleistungen</b></p>
            <p>Mitglied der <b>WKÖ, WKOÖ, FG Unternehmensberatung, Buchhaltung und Informationstechnik, LG Handelsagenten</b></p>
          </Text>
        </ContentWithPaddingXl>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>Datenschutz</Heading>
          </HeadingRow>
          <Text>
          <h1 className="adsimple-211092215" id="privacy">Datenschutzerklärung & Haftungsauschluss</h1>
            <h2 className="adsimple-211092215">Datenschutz</h2>
            <p>Wir haben diese Datenschutzerklärung (Fassung 27.04.2019-211092215) verfasst, um Ihnen gemäß der
                Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 und dem <a tw="text-primary-500" className="adsimple-211092215"
                    href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=211092215"
                    target="_blank" rel="noopener nofollow" className="external">Datenschutzgesetz (DSG)</a> zu
                erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche
                Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.</p>
            <p>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben
                uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu
                beschreiben.</p>
            <h2 className="adsimple-211092215">Automatische Datenspeicherung</h2>
            <p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und
                gespeichert, so auch auf dieser Webseite.</p>
            <p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf
                dem diese Webseite gespeichert ist) automatisch Daten wie</p>
            <ul className="adsimple-211092215">
                <li className="adsimple-211092215">die Adresse (URL) der aufgerufenen Webseite</li>
                <li className="adsimple-211092215">Browser und Browserversion</li>
                <li className="adsimple-211092215">das verwendete Betriebssystem</li>
                <li className="adsimple-211092215">die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
                <li className="adsimple-211092215">den Hostname und die IP-Adresse des Geräts von welchem aus
                    zugegriffen wird</li>
                <li className="adsimple-211092215">Datum und Uhrzeit</li>
            </ul>
            <p>in Dateien (Webserver-Logfiles).</p>
            <p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht.
                Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim
                Vorliegen von rechtswidrigem Verhalten eingesehen werden.<br />
                Die Rechtsgrundlage besteht nach <a tw="text-primary-500" className="adsimple-211092215"
                    href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=211092215"
                    target="_blank" rel="noopener nofollow" className="external">Artikel 6  Absatz 1 f DSGVO</a>
                (Rechtmäßigkeit der Verarbeitung) darin, dass berechtigtes Interesse daran besteht, den
                fehlerfreien Betrieb dieser Webseite durch das Erfassen von Webserver-Logfiles zu ermöglichen.
            </p>
            <h2 className="adsimple-211092215">Cookies</h2>
            <p>Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.<br />
                Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
                Datenschutzerklärung besser verstehen.</p>
            <h3 className="adsimple-211092215">Was genau sind Cookies?</h3>
            <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind
                beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten
                Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
            <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle
                Webseiten verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch anderer
                Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer
                Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im
                Cookie-Ordner, quasi dem „Hirn“ Ihres Browsers, untergebracht. Ein Cookie besteht aus einem
                Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere
                Attribute angegeben werden.</p>
            <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
                Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die
                „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer
                Sie sind und bietet Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat
                jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer
                einzigen Datei gespeichert.</p>
            <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden
                direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B.
                Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere
                Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein
                paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder
                andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</p>
            <p>So können zum Beispiel Cookie-Daten aussehen:</p>
            <ul className="adsimple-211092215">
                <li className="adsimple-211092215">Name: _ga</li>
                <li className="adsimple-211092215">Ablaufzeit: 2 Jahre</li>
                <li className="adsimple-211092215">Verwendung: Unterscheidung der Webseitenbesucher</li>
                <li className="adsimple-211092215">Beispielhafter Wert: GA1.2.1326744211.152211092215</li>
            </ul>
            <p>Ein Browser sollte folgende Mindestgrößen unterstützen:</p>
            <ul className="adsimple-211092215">
                <li className="adsimple-211092215">Ein Cookie soll mindestens 4096 Bytes enthalten können</li>
                <li className="adsimple-211092215">Pro Domain sollen mindestens 50 Cookies gespeichert werden können
                </li>
                <li className="adsimple-211092215">Insgesamt sollen mindestens 3000 Cookies gespeichert werden
                    können</li>
            </ul>
            <h3 className="adsimple-211092215">Welche Arten von Cookies gibt es?</h3>
            <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und
                wird in der folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir
                kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
            <p>Man kann 4 Arten von Cookies unterscheiden:</p>
            <p>
                <strong className="adsimple-211092215">Unbedingt notwendige Cookies<br />
                </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum
                Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf
                anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der
                Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
            <p>
                <strong className="adsimple-211092215">Funktionelle Cookies<br />
                </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige
                Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das
                Verhalten der Website bei verschiedenen Browsern gemessen.</p>
            <p>
                <strong className="adsimple-211092215">Zielorientierte Cookies<br />
                </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden
                eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
            <p>
                <strong className="adsimple-211092215">Werbe-Cookies<br />
                </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User
                individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
            </p>
            <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser
                Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie
                gespeichert.</p>
            <h3 className="adsimple-211092215">Wie kann ich Cookies löschen?</h3>
            <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service
                oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen,
                nur teilweise zuzulassen oder zu deaktivieren. Zum Beispiel können Sie Cookies von
                Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
            <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
                Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen
                finden:</p>
            <p>
                <a tw="text-primary-500" className="adsimple-211092215"
                    href="https://support.google.com/chrome/answer/95647?tid=211092215" className="external"
                    rel="nofollow">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
            </p>
            <p>
                <a tw="text-primary-500" className="adsimple-211092215"
                    href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=211092215"
                    className="external" rel="nofollow">Safari: Verwalten von Cookies und Websitedaten mit
                    Safari</a>
            </p>
            <p>
                <a tw="text-primary-500" className="adsimple-211092215"
                    href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=211092215"
                    className="external" rel="nofollow">Firefox: Cookies löschen, um Daten zu entfernen, die
                    Websites auf Ihrem Computer abgelegt haben</a>
            </p>
            <p>
                <a tw="text-primary-500" className="adsimple-211092215"
                    href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=211092215"
                    className="external" rel="nofollow">Internet Explorer: Löschen und Verwalten von Cookies</a>
            </p>
            <p>
                <a tw="text-primary-500" className="adsimple-211092215"
                    href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=211092215"
                    className="external" rel="nofollow">Microsoft Edge: Löschen und Verwalten von Cookies</a>
            </p>
            <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass
                er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen
                Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser
                verschieden. Am besten ist es Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies
                löschen Chrome” oder „Cookies deaktivieren Chrome“ im Falle eines Chrome Browsers oder tauschen
                das Wort „Chrome“ gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.</p>
            <h3 className="adsimple-211092215">Wie sieht es mit meinem Datenschutz aus?</h3>
            <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das
                Speichern von Cookies eine Einwilligung des Website-Besuchers (also von Ihnen) verlangt.
                Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese
                Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des
                Telekommunikationsgesetzes (TKG).</p>
            <p>Wenn Sie mehr über Cookies wissen möchten und vor technischen Dokumentationen nicht
                zurückscheuen, empfehlen wir <a tw="text-primary-500" className="adsimple-211092215"
                    href="https://tools.ietf.org/html/rfc6265" target="_blank" rel="nofollow noopener"
                    className="external">https://tools.ietf.org/html/rfc6265</a>, dem Request for Comments der
                Internet Engineering Task Force (IETF) namens „HTTP State Management Mechanism“.</p>
            <h2 className="adsimple-211092215">Speicherung persönlicher Daten</h2>
            <p>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel
                Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines
                Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der
                IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte
                weitergegeben.</p>
            <p>Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die
                Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen
                Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter,
                können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten
                eingesehen werden.</p>
            <p>Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir
                keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen,
                vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.</p>
            <p>Die Rechtsgrundlage besteht nach <a tw="text-primary-500" className="adsimple-211092215"
                    href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=211092215"
                    target="_blank" rel="noopener nofollow" className="external">Artikel 6  Absatz 1 a DSGVO</a>
                (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von
                Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen – eine
                formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.</p>
            <h2 className="adsimple-211092215">Rechte laut Datenschutzgrundverordnung</h2>
            <p>Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen <a
                    className="adsimple-211092215"
                    href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=211092215"
                    target="_blank" rel="noopener nofollow" className="external">Datenschutzgesetzes (DSG)</a>
                grundsätzlich die folgende Rechte zu:</p>
            <ul className="adsimple-211092215">
                <li className="adsimple-211092215">Recht auf Berichtung (Artikel 16 DSGVO)</li>
                <li className="adsimple-211092215">Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17
                    DSGVO)</li>
                <li className="adsimple-211092215">Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
                <li className="adsimple-211092215">Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang
                    mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der
                    Verarbeitung (Artikel 19 DSGVO)</li>
                <li className="adsimple-211092215">Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
                <li className="adsimple-211092215">Widerspruchsrecht (Artikel 21 DSGVO)</li>
                <li className="adsimple-211092215">Recht, nicht einer ausschließlich auf einer automatisierten
                    Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden
                    (Artikel 22 DSGVO)</li>
            </ul>
            <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
                datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei
                der Aufsichtsbehörde beschweren, welche in Österreich die Datenschutzbehörde ist, deren Webseite
                Sie unter <a tw="text-primary-500" className="adsimple-211092215" href="https://www.dsb.gv.at/?tid=211092215"
                    className="external" rel="nofollow">https://www.dsb.gv.at/</a> finden.</p>
            <h2 className="adsimple-211092215">Auswertung des Besucherverhaltens</h2>
            <p>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres
                Besuchs dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel
                anonym und wir können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.
            </p>
            <p>Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der
                folgenden Datenschutzerklärung.</p>
            <h2 className="adsimple-211092215">TLS-Verschlüsselung mit https</h2>
            <p>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch
                Technikgestaltung <a tw="text-primary-500" className="adsimple-211092215"
                    href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=211092215"
                    target="_blank" rel="noopener nofollow" className="external">Artikel 25 Absatz 1 DSGVO</a>).
                Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur
                sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen.
                Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol
                links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer
                Internetadresse.</p>
            <h2 className="adsimple-211092215">Google Fonts Lokal Datenschutzerklärung</h2>
            <p>Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA
                94043, USA) auf unserer Webseite. Wir haben die Google-Schriftarten lokal, d.h. auf unserem
                Webserver, nicht Googles Server, eingebunden. Dadurch gibt es keine Verbindung zu Server von
                Google und somit auch keine Datenübertragung bzw. Speicherung.</p>
            <h3 className="adsimple-211092215">Was sind Google Fonts?</h3>
            <p>Google Fonts (früher Google Web Fonts) ist ein interaktives Verzeichnis mit mehr als
                800 Schriftarten, die die <a tw="text-primary-500" className="adsimple-211092215"
                    href="https://de.wikipedia.org/wiki/Google_LLC?tid=211092215" className="external"
                    rel="nofollow">Google LLC</a> zur freien Verwendung bereitstellt. Mit Google Fonts könnte
                man die Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich
                jede Informationsübertragung zum Google-Server zu unterbinden, haben wir die Schriftarten auf
                unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden keine
                Daten an Google Fonts weiter.</p>
            <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle
                Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das
                Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf
                <a tw="text-primary-500" className="adsimple-211092215" href="https://developers.google.com/fonts/faq?tid=211092215"
                    className="external" rel="nofollow">https://developers.google.com/fonts/faq?tid=211092215</a>.
            </p>
            <h2 className="adsimple-211092215">Google Maps Datenschutzerklärung</h2>
            <p>Wir verwenden Google Maps der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA
                94043, USA) auf unserer Webseite.</p>
            <p>Durch die Nutzung der Funktionen dieser Karte werden Daten an Google übertragen. Welche Daten von
                Google erfasst werden und wofür diese Daten verwendet werden, können Sie auf <a
                    className="adsimple-211092215" href="https://www.google.com/intl/de/policies/privacy/"
                    className="external" rel="nofollow">https://www.google.com/intl/de/policies/privacy/</a>
                nachlesen.</p>
            <p>Quelle: Erstellt mit dem <a
                    title="Datenschutz Generator von firmenwebseiten.at"
                    href="https://www.firmenwebseiten.at/datenschutz-generator/" target="_blank" rel="follow"
                    >Datenschutz Generator von firmenwebseiten.at</a> in
                Kooperation mit <a tw="text-primary-500" href="https://www.haustrian.at" target="_blank" rel="follow" title=""
                    >haustrian.at</a>
            </p>
            <h2>EU-Streitschlichtung</h2>
            <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung)
                möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br />
                Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der
                Europäischen Kommission unter <a
                    href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE"
                    target="_blank" className="external" rel="nofollow">http://ec.europa.eu/odr?tid=221092213</a> zu
                richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
            <p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
                Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            <h2>Haftung für Inhalte dieser Webseite</h2>
            <p>Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle
                Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte
                auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.</p>
            <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu
                kontaktieren, Sie finden die Kontaktdaten im Impressum.</p>
            <h2>Haftung für Links auf dieser Webseite</h2>
            <p>Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich
                sind. Haftung für verlinkte Websites besteht laut <a
                    href="https://www.ris.bka.gv.at/Dokument.wxe?Abfrage=Bundesnormen&Dokumentnummer=NOR40025813&tid=221092213"
                    target="_blank" rel="noopener nofollow" className="external">§ 17 ECG</a> für uns nicht, da wir
                keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch
                bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten
                bekannt werden.</p>
            <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie uns zu kontaktieren,
                Sie finden die Kontaktdaten im Impressum.</p>
            <h2>Urheberrechtshinweis</h2>
            <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls
                notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
                verfolgen.</p>
            <p>Quelle: Erstellt mit dem <a
                    title="Datenschutz Generator von firmenwebseiten.at"
                    href="https://www.firmenwebseiten.at/datenschutz-generator/" target="_blank" rel="follow"
                    >Datenschutz Generator von firmenwebseiten.at</a> in
                Kooperation mit <a tw="text-primary-500" href="https://www.hausbaueninfo.at" target="_blank" rel="follow" title=""
                    >hausbaueninfo.at</a>
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
