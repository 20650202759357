import React from "react";
import MainFeature1 from "components/features/TwoColWithButton.js";
import tw from "twin.macro";
import EventGuideImage from "../../images/eventguide-project.png";
import PrinterImage from "../../images/3dprinter-project.png";
import FlightImage from "../../images/flight-project.png";
import HilfAppImage from "../../images/hilfapp.jpg";
import WebHausImage from "../../images/webhaus.jpg";

import { SectionHeading } from "components/misc/Headings.js";
const Subheading = tw.span`uppercase tracking-wider text-sm`;

const Heading = tw(SectionHeading)`w-full mt-12`;

export default () => {
  return (<div id="projects">
    <Heading>Vorgestellte <span tw="text-primary-500">Projekte</span></Heading>
    {/* <MainFeature1
      subheading={<Subheading>Software</Subheading>}
      heading="HilfApp"
      buttonRounded={false}
      primaryButtonText="Mehr erfahren"
      primaryButtonUrl="https://hilfapp.at"
      description="Sie haben ein Problem im Haushalt und suchen einen Techniker oder eine Technikerin? Mit HilfApp finden Sie einfach den passenden Spezialisten in der Nähe. HilfApp wurde von mir entwickelt um es Menschen zu ermöglichen einfach und unkompliziert eine(n) Techniker*in zu engagieren."
      imageSrc={HilfAppImage}
    /> */}
    <MainFeature1
      subheading={<Subheading>Websites</Subheading>}
      heading="WebHaus"
      buttonRounded={false}
      primaryButtonText="Mehr erfahren"
      primaryButtonUrl="https://webhaus.poscher.me"
      description="Mit Webhaus bieten wir Ihnen die Möglichkeit mit unserer Unterstützung einfach eine Website zu erstellen, zu hosten und damit erfolgreich zu werden."
      imageSrc={WebHausImage}
      textOnLeft={false}
    />
    <MainFeature1
      subheading={<Subheading>Software</Subheading>}
      heading="Mobiler 3D-Drucker"
      buttonRounded={false}
      primaryButtonText="Mehr erfahren"
      primaryButtonUrl="https://github.com/PoscherAlexander/mobile-3d-backend"
      description="Im Laufe meiner Ausbildung am Linzer Technikum habe ich mit zwei Kollegen einen 3D-Drucker entworfen, welcher einfach zu- und aufgeklappt werden konnte, sodass dieser überall, wo eine Steckdose ist, verwendet werden konnte. Mein Aufgabenbereich war die Ansteuerung des 3D-Druckers über eine Webobferfläche (Frontend & Backend)"
      imageSrc={PrinterImage}
    />
    <MainFeature1
      subheading={<Subheading>Soziales Projekt</Subheading>}
      heading="Event Guide"
      buttonRounded={false}
      primaryButtonText="Mehr erfahren"
      primaryButtonUrl="https://eventguide.poscher.me/files/folder.pdf"
      description="Mit Event Guide haben ein Freund und ich ein Projekt gestartet, bei dem wir für Unternehmen, welche durch die Corona-Krise stark getroffen wurden, einen kostenlosen Online-Auftritt (Social Media, Webseite und Werbeplakate) aufgebaut haben. Damit auch jene Unternehmen, die sich keine Webseite leisten konnten im Internet bekannt wurden."
      imageSrc={EventGuideImage}
      textOnLeft={false}
    />
  </div>);
}