import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-32 pb-40 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none mb-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Heading2 = styled.h2`
  ${tw`text-lg text-center lg:text-left sm:text-xl lg:text-2xl xl:text-3xl font-semibold text-gray-100 leading-none mt-0`}
  span {
    ${tw`inline-block mt-0`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 rounded-lg transform -skew-x-12 -z-10`}
  }
`;

const PrimaryAction = tw.button`mr-2 px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 hocus:bg-primary-700 font-bold rounded shadow transition duration-300 bg-primary-500 text-gray-100 focus:shadow-outline`;
const SecondaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-gray-400 focus:shadow-outline`;

export default () => {
  return (
    <Container id="home">
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader />
        <TwoColumn>
          <LeftColumn>
            <Heading>
              <SlantedBackground>IT und EDV Services</SlantedBackground>
            </Heading>
            <Heading2>
              <br />
              <span>PC- und Technik-Hilfe, <br/>IT-Beratung und Support, <br/>Web- und Softwareentwicklung</span>
            </Heading2>
            <a href="mailto:office@poscher.me"><PrimaryAction>office@poscher.me</PrimaryAction></a>
            <a href="tel:+4367764161644"><SecondaryAction>+43 677 64 16 16 44</SecondaryAction></a>
          </LeftColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
