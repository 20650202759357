import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Checkbox = (props) => {
  return (
    <div className="relative flex items-start mb-2">
      <div className="flex items-center h-5">
        <input
          aria-describedby="comments-description"
          name={props.name}
          type="checkbox"
          checked={props.checked}
          defaultChecked={props.defaultChecked}
          onClick={props.onChange}
          disabled={props.disabled}
          readOnly={props.readOnly}
          className={classNames(props.disabled ? "text-sky-200" : "text-sky-600",
          "h-4 w-4 border-gray-300 rounded")}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor="comments" className="font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    </div>
  );
}

export default Checkbox;